'use client'

import { cva } from 'class-variance-authority'
import { forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from 'react'
import { cn } from '~/core/ui/utils'

const inputVariants = cva(
  'outline-none min-w-full flex items-center rounded justify-center w-full px-3 disabled:pointer-events-none placeholder:text-gray-500 dark:placeholder:text-gray-400 text-gray-900 dark:text-gray-200 disabled:placeholder:text-gray-400 dark:disabled:placeholder:text-gray-600 disabled:text-gray-400 dark:disabled:text-gray-600',
  {
    variants: {
      variant: {
        defaultNoDestructive:
          'border border-solid border-gray-300 focus:border-primary-300 focus:shadow-select dark:border-gray-600 dark:bg-gray-900 dark:focus:border-primary-700 dark:focus:shadow-dark-select disabled:border-gray-200 disabled:bg-gray-50 dark:disabled:border-gray-700 dark:disabled:bg-gray-800',
        defaultDestructive: 'border border-solid border-red-300 focus:shadow-error dark:focus:shadow-dark-error dark:border-red-700 dark:bg-gray-900',
        ghostNoDestructive:
          'focus:border-primary-300 hover:bg-gray-50 focus:bg-transparent focus:border focus:border-solid focus:shadow-select dark:bg-gray-900 dark:focus:border-primary-700 dark:focus:shadow-dark-select disabled:bg-white dark:disabled:bg-gray-800',
        ghostDestructive:
          'focus:border-red-300 hover:bg-gray-50 focus:bg-transparent focus:border focus:border-solid focus:shadow-select dark:bg-gray-900 dark:focus:border-primary-700 dark:focus:shadow-dark-select disabled:bg-white dark:disabled:bg-gray-800',
      },
      size: {
        lg: 'h-[42px] text-base',
        md: 'h-[38px] text-sm',
        sm: 'h-8 text-sm',
        xs: 'h-6 text-xs',
      },
      focus: {
        default: '',
        focus: 'border border-solid border-primary-300 shadow-select dark:bg-gray-900 dark:border-primary-700 dark:shadow-dark-select',
      },
    },
    defaultVariants: {
      variant: 'defaultNoDestructive',
      size: 'md',
      focus: 'default',
    },
  }
)

type InputSizeProps = 'lg' | 'md' | 'sm' | 'xs'
type InputVariantProps = 'default' | 'ghost'
interface InputOptionsProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'value' | 'onChange' | 'onBlur'> {
  onChange?: (value: string | number) => void
  onBlur?: (value: string | number) => void
  onChangeEnter?: () => void
  inputType?: 'text' | 'number'
  defaultValue?: string | number
  value?: string | number

  isDisabled?: boolean
  destructive?: boolean
  className?: string
  placeholder?: string

  variant?: InputVariantProps
  size?: InputSizeProps
  isFocus?: boolean
  config?: object
  autoFocusFirstLoad?: boolean
}
interface InputProps extends InputOptionsProps {}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange = undefined,
      onBlur = undefined,
      onChangeEnter = undefined,
      inputType = 'text',
      isDisabled = false,
      destructive = false,
      className = '',
      variant = 'default',
      size = 'md',
      isFocus = false,
      autoFocusFirstLoad = false,
      ...props
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [focusFirstLoad, setFocus] = useState(true);

    useEffect(() => {
      if (focusFirstLoad && inputRef && autoFocusFirstLoad) {
        setFocus(false)
        setTimeout(() => {
          inputRef?.current?.focus()
        }, 250)
      }
    }, [inputRef])

    return (
      <input
        ref={autoFocusFirstLoad ? inputRef : ref}
        type={inputType}
        disabled={isDisabled}
        className={cn(
          'cursor-pointer',
          props.type === 'number' ? '[&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none' : '',
          inputVariants({
            variant: `${variant}${destructive ? 'Destructive' : 'NoDestructive'}`,
            size,
            focus: isFocus ? 'focus' : 'default',
            className,
          })
        )}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value)
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && onChangeEnter) {
            onChangeEnter()
          }
        }}
        onBlur={(e) => {
          if (onBlur) {
            onBlur(e.target.value)
          }
        }}
        {...props}
      />
    )
  }
)

Input.displayName = 'Input'

export { Input }
export type { InputOptionsProps, InputProps, InputSizeProps, InputVariantProps }
